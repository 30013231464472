// NOTE: this is used to provide default value for object-format component data with multiple level keys, like: config
import deepGet from "lodash/get"

function isPureObject(value) {
  return typeof value === 'object' && !Array.isArray(value) && value !== null
}

export default function(objectWithDefaults, dataModel) {
  console.log('mergeDefaultAndDataModel.js called', objectWithDefaults, dataModel)

  const provideDefaultForObject = function (objectWithDefaults, dataModel) {
    Object.keys(objectWithDefaults).forEach(key => {
      if (isPureObject(objectWithDefaults[key])) {
        objectWithDefaults[key] = provideDefaultForObject(objectWithDefaults[key], deepGet(dataModel, key, null))
      } else {
        objectWithDefaults[key] = deepGet(dataModel, key, objectWithDefaults[key])
      }
    })

    return objectWithDefaults
  }

  return provideDefaultForObject(objectWithDefaults, dataModel)
}
